@import '../../assets/styles/common/mixin';
@import '../../assets/styles/common/variables';

.loading-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &.full-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 20000;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
