@import '../../assets/styles/common/mixin';
@import '../../assets/styles/common/variables';

.breadcrumb {
  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  :not(a[color]) {
    color: #75767a;
  }
  svg {
    width: 16px;
    height: 16px;
  }
}
