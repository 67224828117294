@import '../../../../assets/styles/common/mixin';
@import '../../../../assets/styles/common/variables';

.global-alert-component{
  position: relative;
  z-index: 1100;
  #airasia-phoenix-modal-container{
    &.open {
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}