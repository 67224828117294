.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.content-limit {
  margin: auto;
  max-width: 1224px;
  width: 100%;
  padding: 0 16px;
}

.center-page {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate.direction90 {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate.direction180 {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate.direction270 {
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

@keyframes popup-to-top {
  from {
    top: 100vh;
    opacity: 0.5;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
