@import '../../assets/styles/common/mixin';
@import '../../assets/styles/common/variables';

.show-more {
  .show-more-link {
    margin: 4px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #4b4fa6;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
